<template>
    <user-dashboard-layout>
        <v-container>
            <v-row dense>
                <v-col md="6" sm="12" ref="editorContainer">
                    <div v-if="! editMode">
                        <v-img :src="infographicAvatar" />
                    </div>
                    <div v-else >
                        <v-btn @click="handleDownloadKonva">{{$t('guides.' + guides.btndld)}}</v-btn>
                        hue:
                        <input ref="hueSlider" id="hue" type="range" min="0" max="259" step="1" value="150" />

                        <v-stage :config="configKonva" ref="konvaStage"
                                 @mousedown="handleStageMouseDown"
                                 @touchstart="handleStageMouseDown"
                        >
                            <v-layer>
                                <v-image ref="konvaImage" :config="imageConfig"/>
                                <v-rect :config="rectConfig"/>
                                <v-image ref="photo" :config="photoConfig"  @transformend="handleTransformEnd"/>
                                <v-image ref="logo" :config="logoConfig"  @transformend="handleTransformEnd"/>
                                <v-text ref="company" :config="companyConfig"  @transformend="handleTransformEnd"/>
                                <v-text ref="name" :config="fullNameConfig"  @transformend="handleTransformEnd"/>
<!--                                <v-text ref="job_title" :config="jobTitleConfig"/>-->
                                <v-text ref="registration_id" :config="registrationIdConfig"  @transformend="handleTransformEnd"/>
                                <v-text ref="phone" :config="phoneConfig"  @transformend="handleTransformEnd"/>
                                <v-transformer ref="transformer" />
<!--                                <v-text ref="website" :config="websiteConfig"/>-->
                            </v-layer>
                        </v-stage>
                    </div>
                </v-col>
                <v-col md="6" sm="12" class="pl-15 mt-15">
                    <v-row>
                        <h1 v-text="infographic.name"/>
                    </v-row>
                    <v-row>
                        <v-col v-text="infographic.description"/>
                    </v-row>
                    <v-row>
                        <v-btn @click="initEditor">{{$t('infographics.' + infographics.btnctmz)}}</v-btn>
                    </v-row>
                    <v-row>
                        <v-list shaped v-if="editMode">
                            <v-list-item-group v-model="personalizationList" multiple>
                                <template v-for="(item, i) in items">
                                    <v-divider v-if="!item" :key="`divider-${i}`"/>
                                    <v-list-item
                                        v-else
                                        :key="`item-${i}`"
                                        :value="item"
                                        active-class="deep-purple--text text--accent-4">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item"/>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-checkbox
                                                    @change="toggleDisplay(item)"
                                                    :input-value="active"
                                                    color="deep-purple accent-4"/>
                                            </v-list-item-action>
                                        </template>
                                    </v-list-item>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
    import Infographic from "../../../models/Infographic";
    import Konva from 'konva';
    import User from "../../../models/User";
    import {mapState} from "vuex";
    import KonvaHelper from "../../../helpers/KonvaHelper";
    import infographics from "@/configs/infographics";
    import guides from "@/configs/guides";

    export default {
        name: "Show",
        components: {UserDashboardLayout},
        computed: {
            infographicAvatar() {
                return this.infographic.files
            },
            ...mapState({
                user: state => state.auth.user
            })
        },
        data: function () {
            return {
                carousel: 0,
                items: ['logo', 'company', 'name',  'photo', 'registration_id', 'phone',  ],
                personalizationList: ['logo', 'company', 'name',  'photo', 'registration_id', 'phone',  ],
                infographic: {
                    files: '',
                },
                infographics,
                guides,
                editMode: false,
                selectedLanguage: '',
                configKonva: {
                    width: 400,
                    height: 800
                },
                selectedShapeName: '',
                image: null,
                rectConfig: {x: 0, y: 50, width: 100, height: 100, fill: 'red'},
                personalization: {},
                imageConfig: { image: null,  filters: [Konva.Filters.HSL],},
                logoConfig: {x: 10, y: 50, width: 80, height: 80, image: null, draggable: true,    name: "logo",},
                photoConfig: {x: 10, y: 50, width: 80, height: 80, image: null, draggable: true,    name: "photo",},
                companyConfig: {
                    fontSize: 22,
                    fill: '#FFFFFF',
                    x: 210,
                    width: 300,
                    height: 60,
                    name: "company",
                    text: 'blabla inc.',
                    draggable: true
                },
                fullNameConfig: {
                    fontSize: 22,
                    fill: '#FFFFFF',
                    x: 210,
                    name: "fullName",
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },
           /*     jobTitleConfig: {
                    fontSize: 18,
                    fill: '#FFFFFF',
                    x: 70,
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },*/
                registrationIdConfig: {
                    fontSize: 22,
                    fill: '#FFFFFF',
                    x: 300,
                    name: "registrationId",
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },
                phoneConfig: {
                    fontSize: 22,
                    fill: '#FFFFFF',
                    x: 300,
                    height: 60,
                    name: "phone",
                    text: 'blabla inc.',
                    draggable: true
                },
              /*  websiteConfig: {
                    fontSize: 18,
                    fill: '#FFFFFF',
                    x: 70,
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },*/
            }
        },
        methods: {
            handleDownloadKonva() {
                window.axios.post('/api/infographics/' + this.infographic.id + '/increment-downloads')
                KonvaHelper.downloadKonvaImage(this.$refs.konvaStage, 'infographic.png')
            },
            async initEditor() {
                let width = this.$refs.editorContainer.clientWidth;
                let image = await KonvaHelper.loadImage(this.infographicAvatar, width)
                let logo = await KonvaHelper.loadImage(this.personalization.logo_url)
                let photo = await KonvaHelper.loadImage(this.$auth.user().avatar_url)

                this.imageConfig.image = image;

                this.photoConfig.image = photo;
                this.photoConfig.y = image.height + 10;

                this.logoConfig.image = logo;
                this.logoConfig.x = 100;
                this.logoConfig.y = image.height + 10;

                this.companyConfig.y = image.height + 20;
                this.companyConfig.text = this.personalization.company;

                this.fullNameConfig.y = image.height + 50;
                this.fullNameConfig.text = this.personalization.name;

                this.configKonva.width = width
                this.configKonva.height = image.height + 100

                this.registrationIdConfig.text = this.personalization.registered_id;
                this.registrationIdConfig.x = width - 300;
                this.registrationIdConfig.y = image.height + 50;

                this.phoneConfig.text = this.personalization.email;
                this.phoneConfig.x = width - 300;
                this.phoneConfig.y = image.height + 20;

                this.rectConfig.y = image.height
                this.rectConfig.width = width
                this.rectConfig.fill = this.personalization.color

                this.editMode = true;
                setTimeout(() => {
                    var slider = this.$refs.hueSlider;
                    let update = () => {
                        const imgNode = this.$refs.konvaImage.getNode();
                        imgNode.hue(parseFloat(slider.value) );
                        imgNode.getLayer().draw();
                        imgNode.cache();
                    }
                    slider.oninput = update;
                    update();
                }, 1000)
            },
            handleTransformEnd(e) {
                this[this.selectedShapeName+'Config'] = KonvaHelper.handleTransformEnd(e, this[this.selectedShapeName+'Config'])
            },
            handleStageMouseDown(e) {

                // clicked on stage - clear selection
                if (e.target === e.target.getStage()) {
                    this.selectedShapeName = '';
                    KonvaHelper.updateTransformer(this.$refs.transformer, this.selectedShapeName);
                    return;
                }
                // clicked on transformer - do nothing
                const clickedOnTransformer =
                    e.target.getParent().className === 'Transformer';
                if (clickedOnTransformer) {
                    return;
                }
                // find clicked rect by its name
                const name = e.target.name();
                console.log(name)
                if (name) {
                    this.selectedShapeName = name;
                } else {
                    this.selectedShapeName = '';
                }
                KonvaHelper.updateTransformer(this.$refs.transformer, this.selectedShapeName);
            },
            toggleDisplay(item) {
                let refNode = this.$refs[item.toLowerCase()].getNode();

                if (this.personalizationList.indexOf(item) > -1) {
                    refNode.hide()
                } else {
                    refNode.show()
                }

                refNode.getLayer().draw()

                refNode.cache()
            },
        },
        async mounted() {
            const infographicId = this.$route.params.id
            this.infographic = await Infographic.find(infographicId)
            const user = await User.include(['personalization']).find(this.$auth.user().id)
            this.personalization = user.personalization
            this.selectedLanguage = this.infographic.files[0].language
        },
    }
</script>
